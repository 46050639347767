<template>
  <div class="info">
    <!-- <p class="tips">修改登录密码</p> -->
    <template v-if="!actions">
      <van-cell title="记得原密码，修改登录密码" is-link @click="actions = 2" />
      <van-cell title="设置登录密码" is-link @click="actions = 1" />
    </template>

    <template v-if="actions === 1">
      <van-field v-model="mobile" type="tel" label="手机号" readonly />
      <van-field
        v-model="captcha"
        center
        clearable
        label="验证码"
        placeholder="请输入短信验证码"
        maxlength="6"
      >
        <template #button>
          <button v-if="codeShow" class="get_code" @click.prevent="getCode">
            发送验证码
          </button>
          <p v-else class="count_down">{{ codeCountDown }}s后重新获取</p>
        </template>
      </van-field>
    </template>
    <van-field
      v-model="psw"
      type="password"
      label="原密码"
      placeholder="请输入原密码"
      maxlength="10"
      v-if="actions === 2"
    />
    <van-field
      v-model="psw2"
      center
      type="password"
      label="新密码"
      placeholder="请输入新密码"
      maxlength="10"
      v-if="actions"
    >
    </van-field>

    <p class="Error" v-show="error">
      <svg-icon icon-class="login_note" />{{ error }}
    </p>
    <p class="Error" v-show="!error"></p>
    <div class="button" v-show="actions">
      <van-button @click="changePassword" block round color="#009A4D"
        >确认</van-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getToken, setToken, removeToken } from "@/utils/auth";
export default {
  name: "password",
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      actions: 0,
      psw: "",
      psw2: "",
      error: "",
      mobile: "",
      captcha: "",
      codeShow: true, //验证码显示
      codeCountDown: 59, //验证码倒计时
    };
  },
  watch: {
    actions(val) {
      if (val === 1) {
        this.$route.meta.title = "设置登录密码";
        this.$router.replace("/userData/password?actions=1");
      }
    },
  },

  mounted() {
    this.$route.meta.title = "登录密码";
    this.$router.replace("/userData/password?actions=0");
    this.mobile =
      this.userInfo.userEntity.telNum.substr(0, 3) +
      "****" +
      this.userInfo.userEntity.telNum.substr(7);
  },

  methods: {
    async getCode() {
      await this.$API.login
        .sendCAPTCHA({
          mobile: this.userInfo.userEntity.telNum,
        })
        .then((res) => {
          this.Toast.success("发送成功");
          this.codeShow = false;
          let t = setInterval(() => {
            this.codeCountDown--;
            if (this.codeCountDown <= 0) {
              this.codeShow = true;
              clearInterval(t);
              this.codeCountDown = 29;
            }
          }, 1000);
        })
        .catch(() => {
          this.Toast.fail("发送失败");
        });
    },
    async changePassword() {
      this.error = "";
      if (this.actions === 1) {
        if (this.captcha.length !== 6) {
          this.error = "请输入正确短信验证码";
          return;
        }
        if (this.psw2.length < 6) {
          this.error = "新密码长度最少为6位";
          return;
        }

        await this.$API.login
          .verificationCAPTCHA({
            mobile: this.userInfo.userEntity.telNum,
            captcha: this.captcha,
          })
          .then((res) => {
            if (res.data.data == null) {
              this.error = "验证码错误";
            } else if (res.data.data.result) {
              this.updateUser();
            }
          });
      } else if (this.actions === 2) {
        if (this.psw.length < 6) {
          this.error = "请输入正确密码";
        } else if (this.psw2.length < 6) {
          this.error = "新密码长度最少为6位";
        } else if (this.psw === this.psw2) {
          this.error = "两次密码相同";
        } else {
          this.error = "";
          let user = this.userInfo.userEntity;
          await this.$API.user
            .matchePassword({
              object: {
                actualName: user.actualName,
                freeze: user.freeze,
                id: user.id,
                name: user.name,
                password: this.psw,
                referrerUserId: user.referrerUserId,
                telNum: user.telNum,
                userType: user.userType,
                username: user.username,
              },
            })
            .then((res) => {
              if (res.data.data) {
                this.updateUser();
              } else {
                this.error = "原密码错误";
              }
            })
            .catch((err) => {});
        }
      }
    },
    async updateUser() {
      let user = this.userInfo;
      await this.$API.user
        .updateUser({
          object: {
            id: user.id,
            userDetailEntity: user.userDetailEntity,
            userEntity: {
              actualName: user.userEntity.actualName,
              freeze: user.userEntity.freeze,
              id: user.userEntity.id,
              name: user.userEntity.name,
              password: this.psw2,
              referrerUserId: user.userEntity.referrerUserId,
              telNum: user.userEntity.telNum,
              userType: user.userEntity.userType,
              username: user.userEntity.username,
            },
          },
        })
        .then((res) => {
          removeToken();
          this.$store.commit("user/LoginOut", "");
          this.$router.replace("/login");
          this.Toast.success("修改成功 需重新登录");
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  background: #f5f5f5;
  position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  bottom: 0;
  // .tips {
  //   color: #666666;
  //   padding: 12px 15px;
  //   line-height: 14px;
  // }

  .Error {
    text-indent: 10px;
    .svg-icon {
      margin-right: 6px;
    }
    height: 18px;
    font-size: 12px;
    color: #f61423;
    margin-top: 8px;
  }
  .get_code {
    width: 76px;
    height: 24px;
    background: #ffffff;
    border-radius: 13px 13px 13px 13px;
    opacity: 1;
    border: 1px solid #0787f4;
    font-size: 12px;
    color: #0787f4;
  }
  .count_down {
    height: 24px;
    background: #f6f6f6;
    border-radius: 13px 13px 13px 13px;
    font-size: 12px;
    color: #999;
    padding: 0 8px;
  }
  .button {
    padding: 0 15px;
    ::v-deep .van-button {
      margin-top: 40px;
    }
  }
}
</style>
